.modalix {
  color: #fbc515;
  font-size: 14px;
}
div:where(.swal2-container) button:where(.swal2-close) {
  width: 24px !important;
  height: 24px !important;
  padding: 0 !important;
  font-size: 20px !important;
  border: 1px solid #fff !important;
  border-radius: 100% !important;
  /*margin: 5px;
  */
  position: absolute !important;
  right: 5px !important;
  top: 5px !important;
}

div:where(.swal2-container) button:where(.swal2-close):focus {
  outline: none;
  box-shadow: none !important;
}

.react-tel-input {
  border-radius: 8px;
  border: 1px solid #4f4f4f;
  background: linear-gradient(110deg, #151313 0%, #323131 100%);
}

.react-tel-input .form-control {
  background: transparent !important;
  border: 0 !important;
  color: #d5d5d5 !important;
  font-size: 16px !important;
  height: 54px !important;
}

.react-tel-input .flag-dropdown {
  background: transparent !important;
  border: 0 !important;
  color: #d5d5d5 !important;
}

.react-tel-input .selected-flag .arrow {
  border-top: 4px solid #d5d5d5 !important;
}
.react-tel-input .selected-flag:hover,
.react-tel-input .selected-flag:focus {
  background-color: transparent !important;
}
.react-tel-input .country-list {
  background: linear-gradient(110deg, #151313 0%, #323131 100%) !important;
}
.react-tel-input .country-list .country.highlight,
.react-tel-input .country-list .country:hover {
  background-color: #26211b !important;
}
.react-tel-input .flag-dropdown.open .selected-flag {
  background: transparent !important;
}
.css-1dimb5e-singleValue {
  color: #fff !important;
}
