.btn-common {
  background: #3ac4f2;
  border-radius: 5px;
  color: #fff;
  line-height: 27px;
  height: 50px;
  font-weight: 500;
  font-size: 16px;
  font-family: "Poppins", sans-serif !important;
}
.btn-common:hover,
.btn-common:focus,
.btn-common:active {
  background: #51cef8;
  color: #fff;
  border-color: #51cef8;
}
.btn-forgot {
  background: rgba(58, 196, 242, 0.15);
  border-radius: 5px;
  color: #3ac4f2;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  height: 50px;
  font-family: "Poppins", sans-serif !important;
}
.btn-forgot:hover,
.btn-forgot:focus,
.btn-forgot:active {
  background: rgba(58, 196, 242, 0.3);
  color: #3ac4f2;
  border-color: #3ac4f2;
}
