.menu {
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.menu li {
  display: block;
  list-style: none;
  padding: 5px 0px 5px 0px;
}

.menu li a {
  text-decoration: none;
  font-weight: 500;
  font-size: 15px;
  line-height: 27px;
  color: rgba(34, 41, 67, 0.6);
  padding: 0px 0px 0px 10px;
  display: block;
  position: relative;
  white-space: nowrap;
}
.active {
  color: #3ac4f2 !important;
}

.menu li a:hover {
  color: #3ac4f2;
}

.menu li a:hover:before {
  content: "";
  width: 14px;
  height: 30px;
  position: absolute;
  background: #3ac4f2;
  left: -17px;
  border-radius: 0px 15px 15px 0px;
  top: -1px;
}
.menu li a.active:before {
  content: "";
  width: 14px;
  height: 30px;
  position: absolute;
  background: #3ac4f2;
  left: -17px;
  border-radius: 0px 15px 15px 0px;
  top: -1px;
}

.count-menu {
  width: 20px;
  height: 20px;
  display: inline-flex;
  color: #fff;
  border-radius: 100%;
  background: #d32f2f;
  justify-content: center;
  align-items: center;
  margin-left: 5px;
}